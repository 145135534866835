import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    userDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getusers
        usersFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getusers
        officeFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.offices = entities;
            state.error = null;
        },
        //getuserDetails
        userDetailsFetched: (state, action) => {
            const { userDetails } = action.payload;
            state.actionsLoading = false;
            if(userDetails) delete userDetails.password;
            // if(userDetails ) delete userDetails.service_password;
            state.userDetails = userDetails;
            state.error = null;
        },
        // updateuser
        userUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.userDetails = action.payload.user;
        },
        userCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        userDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.user_id
            );
        },
    },
});
