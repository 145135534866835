//@ts-check
// import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import * as auth from "../app/containers/Auth/_redux/authRedux";
import { userSlice } from "../app/containers/User/_redux/userReducer";
import { doctorSlice } from "../app/containers/Doctor/_redux/doctorReducer";
import { storeSlice } from "../app/containers/Store/_redux/storeReducer";
import { categorySlice } from "../app/containers/Category/_redux/categoryReducer";
import { doctorCategorySlice } from "../app/containers/DoctorCategory/_redux/categoryReducer";
import { dashboardSlice } from "../app/containers/Dashboard/_redux/dashboardReducer";
import { cmsSlice } from "../app/containers/cms/_redux/cmsReducer";
import { contactSlice } from "../app/containers/contacts/_redux/contactReducer";
import { labSlice } from "../app/containers/Lab/_redux/labReducer";
import { faqSlice } from "../app/containers/Faq/_redux/faqReducer";
import { serviceChargeSlice } from "../app/containers/Settings/_redux/serviceChargeReducer";


export const rootReducer = combineReducers({
    toastr: toastrReducer,
    auth: auth.reducer,
    user: userSlice.reducer,
    doctor: doctorSlice.reducer,
    store: storeSlice.reducer,
    faq: faqSlice.reducer,
    category: categorySlice.reducer,
    doctorCategory: doctorCategorySlice.reducer,
    dashboard: dashboardSlice.reducer,
    cms: cmsSlice.reducer,
    contact: contactSlice.reducer,
    lab: labSlice.reducer,
    serviceCharge: serviceChargeSlice.reducer,
});

/*export function* rootSaga() {
  yield all([auth.saga()]);
} */
