export const BASE_URL = process.env.REACT_APP_BASE_URL //production base url
export const API_VERSION = "/api/v1/"

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i


// //This regex only allow numbers
//This regex allows numbers and brackets and plus and hiphen, eg (+91)-12321315-654
export const PHONE_REGEX = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/

export const SOCIAL_SECURITY_CODE_REGEX = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/


//Admin Auth
export const LOGIN_URL = API_VERSION + "auth/login";
export const DRIVER_REGISTER_URL = API_VERSION + "auth/driver/register";
export const LOGOUT_URL = API_VERSION + "auth/logout";
export const REQUEST_PASSWORD_URL = API_VERSION + "auth/forgotPassword";
export const RESET_PASSWORD_URL = API_VERSION + "auth/resetPassword";


//Admin Profile
export const ADMIN_LOGIN_URL = API_VERSION + "panel/admin/adminLogin";
export const EDIT_ADMIN_PROFILE = API_VERSION + "panel/admin/editProfile";
export const GET_ADMIN_PROFILE = API_VERSION + "panel/admin/getUserProfile";


//Admin supplier
export const GET_ALL_SUPPLIERS_WITH_FILTER = API_VERSION + "panel/admin/supplier/getSuppliersWithFilter";
export const GET_SUPPLIER_PROFILE = API_VERSION + "panel/admin/supplier/getSupplierById";
export const ADD_SUPPLIER  = API_VERSION + "panel/admin/supplier/addSupplier";
export const EDIT_SUPPLIER = API_VERSION + "panel/admin/supplier/editSupplier";
export const DELETE_SUPPLIER = API_VERSION + "panel/admin/supplier/removeSupplier";


//Admin Categories
export const GET_ALL_CATEGORYS_WITH_FILTER = API_VERSION + "panel/admin/category/getCategoriesWithFilter";
export const GET_CATEGORY_DETAIL = API_VERSION + "panel/admin/category/getCategoryById";
export const ADD_CATEGORY = API_VERSION + "panel/admin/category/addCategory";
export const EDIT_CATEGORY = API_VERSION + "panel/admin/category/editCategory";
export const DELETE_CATEGORY = API_VERSION + "panel/admin/category/deleteCategory";

//Doctor category
export const GET_ALL_DOCTOR_CATEGORYS_WITH_FILTER = API_VERSION + "panel/admin/category/doctor/getCategoriesWithFilter";
export const GET_DOCTOR_CATEGORY_DETAIL = API_VERSION + "panel/admin/category/doctor/getCategoryById";
export const ADD_DOCTOR_CATEGORY = API_VERSION + "panel/admin/category/doctor/addCategory";
export const EDIT_DOCTOR_CATEGORY = API_VERSION + "panel/admin/category/doctor/editCategory";
export const DELETE_DOCTOR_CATEGORY = API_VERSION + "panel/admin/category/doctor/deleteCategory";


//Admin Store
export const GET_ALL_STORES_WITH_FILTER = API_VERSION + "panel/admin/store/getStoresWithFilter";
export const GET_STORE_PROFILE = API_VERSION + "panel/admin/store/getStoreById";
export const ADD_STORE = API_VERSION + "panel/admin/store/addStore";
export const EDIT_STORE = API_VERSION + "panel/admin/store/editStore";
export const DELETE_STORE = API_VERSION + "panel/admin/store/removeStore";


//Admin USER
export const GET_ALL_USERS_WITH_FILTER = API_VERSION + "panel/admin/user/getUsersWithFilter";
export const GET_USER_PROFILE = API_VERSION + "panel/admin/user/getUserById";
export const GET_USER_DETAILS = API_VERSION + "panel/admin/user/getUserDetailsById";
export const ADD_USER = API_VERSION + "panel/admin/user/addUser";
export const EDIT_USER = API_VERSION + "panel/admin/user/editUser";
export const DELETE_USER = API_VERSION + "panel/admin/user/removeUser";


//Admin Doctor
export const GET_ALL_DOCTORS_WITH_FILTER = API_VERSION + "panel/admin/doctor/getDoctorsWithFilter";
export const GET_DOCTOR_PROFILE = API_VERSION + "panel/admin/doctor/getDoctorById";
export const ADD_DOCTOR = API_VERSION + "panel/admin/doctor/addDoctor";
export const EDIT_DOCTOR = API_VERSION + "panel/admin/doctor/editDoctor";
export const DELETE_DOCTOR = API_VERSION + "panel/admin/doctor/removeDoctor";
export const GET_ALL_DOCTORS_CATEGORY = API_VERSION + "panel/admin/doctor/getDoctorCategories";
export const UPDATE_DOCTOR_TIME_SLOT = API_VERSION + "panel/admin/doctor/addSlot";
export const GET_DOCTOR_TIME_SLOT_BY_DAY = API_VERSION + "panel/admin/doctor/getSlot";


//Spare Parts
export const GET_ALL_SPARE_PARTS_WITH_FILTER = API_VERSION + "panel/admin/supplier/getSparePartsWithFilter";
export const GET_SPARE_PART_PROFILE = API_VERSION + "panel/admin/supplier/getSparePartById";
export const ADD_SPARE_PART = API_VERSION + "panel/admin/supplier/addSparePart";
export const EDIT_SPARE_PART = API_VERSION + "panel/admin/supplier/editSparePart";
export const DELETE_SPARE_PART = API_VERSION + "panel/admin/supplier/removeSparePart";


//Faq
export const GET_FAQ_DETAIL = API_VERSION + "panel/admin/faq/getFaqById";
export const GET_ALL_FAQS_WITH_FILTER = API_VERSION + "panel/admin/faq/getFaqsWithFilter";
export const ADD_FAQ = API_VERSION + "panel/admin/faq/addFaq";
export const EDIT_FAQ = API_VERSION + "panel/admin/faq/editFaq";
export const DELETE_FAQ = API_VERSION + "panel/admin/faq/removeFaq";


//Labs
export const GET_ALL_LABS_WITH_FILTER = API_VERSION + "panel/admin/lab/getLabsWithFilter";
export const GET_LAB_PROFILE = API_VERSION + "panel/admin/lab/getLabById";
export const ADD_LAB = API_VERSION + "panel/admin/lab/addLab";
export const EDIT_LAB = API_VERSION + "panel/admin/lab/editLab";
export const DELETE_LAB = API_VERSION + "panel/admin/lab/removeLab";


//Service charge
export const UPDATE_LAB_FEE = API_VERSION + "panel/admin/setting/updateLabFee";
export const UPDATE_DOCTOR_FEE = API_VERSION + "panel/admin/setting/updateDoctorFee";
export const GET_ALL_SERVICE_CHARGE = API_VERSION + "panel/admin/setting/getAllServiceCharge";



//Cms
export const GET_CMS_DETAILS = API_VERSION + "panel/admin/cms/get-cms";
export const EDIT_CMS = API_VERSION + "panel/admin/cms/update-cms";
export const DELETE_CMS = API_VERSION + "panel/admin/cms/remove-cms";


//Cms
export const GET_CONTACT_DETAILS = API_VERSION + "panel/admin/contact/getContact";
export const EDIT_CONTACT = API_VERSION + "panel/admin/contact/updateContact";
export const DELETE_CONTACT = API_VERSION + "panel/admin/contact/removeContact";


//Admin Dashboard
export const GET_DASHBOARD_STATS = API_VERSION + "carrier/dashboard/getDashboardData";

//Admin Driver
export const GET_DRIVER_PROFILE_DETAILS = API_VERSION + "driver/getDriverProfile";



