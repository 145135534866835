//@ts-check
import React, { Suspense, lazy,useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../layout/_core/SplashScreen";
import {useSelector,shallowEqual,useDispatch,connect,} from 'react-redux';
import { useLocation } from "react-router";
import {getUserProfile} from "./containers/Auth/_redux/authCrud";
import {actions} from "./containers/Auth/_redux/authRedux";


const DashboardPages = lazy(() => import("./containers/Dashboard/index"));
const StorePages = lazy(() => import("./containers/Store/index"));
const UserPages = lazy(() => import("./containers/User/index"));
const DoctorPages = lazy(() => import("./containers/Doctor/index"));
const AboutUsPages = lazy(() => import("./containers/cms/index"));
const CategoryPages = lazy(() => import("./containers/Category/index"));
const DoctorCategoryPages = lazy(() => import("./containers/DoctorCategory/index"));
const LabPages = lazy(() => import("./containers/Lab/index"));
const MyProfile = lazy(() => import("./containers/Profile/index"));
const ContactPages = lazy(() => import("./containers/contacts/index"));
const SettingsPages = lazy(() => import("./containers/Settings/index"));
const FaqPages = lazy(() => import("./containers/Faq/index"));
const NotFound = lazy(() => import("./components/404Page"));

function BasePage(props) {
    const user_type = useSelector((state) => state.auth.user.userType, shallowEqual);
    const location = useLocation();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/dashboard" />
                <Route path="/my-profile"     component={MyProfile} />
                <Route path="/store"          component={StorePages} />
                <Route path="/dashboard"      component={DashboardPages} />
                <Route path="/category"       component={CategoryPages} />
                <Route path="/doctor-category"       component={DoctorCategoryPages} />
                <Route path="/user"     component={UserPages} />
                <Route path="/doctors"     component={DoctorPages} />
                <Route path="/lab"     component={LabPages} />
                <Route path="/cms"     component={AboutUsPages} />
                <Route path="/consultation-fee"     component={SettingsPages} />
                <Route path="/contact"     component={ContactPages} />
                <Route path="/faq"     component={FaqPages} />
                <Route path="/404"  component={NotFound}/>
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    );
}



const mapDispatchToProps = (dispatch)=>{
    return{
        saveData : (data)=> dispatch(actions.setUser(data))
    }
}
export default connect(null,mapDispatchToProps)(BasePage)
