import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    doctorDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const doctorSlice = createSlice({
    name: "doctor",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getdoctors
        doctorsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getdoctors
        categoryFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.category = entities;
            state.error = null;
        },
        //getdoctorDetails
        doctorDetailsFetched: (state, action) => {
            const { doctorDetails } = action.payload;
            state.actionsLoading = false;
            if(doctorDetails) delete doctorDetails.password;
            // if(doctorDetails ) delete doctorDetails.service_password;
            state.doctorDetails = doctorDetails;
            state.error = null;
        },
        //doctorTimeslotDetailsByDayFetched
        doctorTimeslotDetailsByDayFetched: (state, action) => {
            const { timeSlotDetails } = action.payload;
            state.actionsLoading = false;
            state.timeSlotDetails = timeSlotDetails;
            state.error = null;
        },
        // updatedoctor
        doctorUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.doctorDetails = action.payload.doctor;
        },
        // update doctor time slot
        updateDoctorTimeSlot: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.doctorTimeSlot = action.payload.doctorTimeSlot;
        },
        doctorCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        doctorDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.doctor_id
            );
        },
    },
});
