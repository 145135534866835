import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    storeDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const storeSlice = createSlice({
    name: "store",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getstores
        storesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getstores
        officeFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.offices = entities;
            state.error = null;
        },
        //getstoreDetails
        storeDetailsFetched: (state, action) => {
            const { storeDetails } = action.payload;
            state.actionsLoading = false;
            if(storeDetails) delete storeDetails.password;
            // if(storeDetails ) delete storeDetails.service_password;
            state.storeDetails = storeDetails;
            state.error = null;
        },
        // updatestore
        storeUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.storeDetails = action.payload.store;
        },
        storeCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        storeDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.store_id
            );
        },
    },
});
